body {
  color: #fff;
  background-color: #121212;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;
  margin: 0;
  font-family: Wiener Sans, sans-serif;
  display: flex;
}

header {
  color: #fff;
  z-index: 1;
  background-color: #1e1e1e;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 20px;
  display: flex;
  position: fixed;
  top: 0;
}

.left-images {
  align-items: center;
  display: flex;
}

.left-images .large-image {
  width: 60px;
  height: auto;
  margin-right: 10px;
}

.left-images img[src="assets/turbo.png"] {
  width: 80px;
  height: auto;
}

.left-images img:first-child {
  margin-left: 15px;
}

header .right {
  align-items: center;
  display: flex;
}

header .right .hidden {
  display: none;
}

header #balance-info {
  color: #fff;
  margin-right: 20px;
}

header #connect-button, header #wallet-info {
  cursor: pointer;
  color: #fff;
  background-color: #444;
  border: none;
  border-radius: 5px;
  align-items: center;
  margin-right: 20px;
  padding: 5px 10px;
  display: flex;
}

header #connect-button img, header #wallet-info img {
  height: 24px;
  margin-right: 5px;
}

header #wallet-info {
  display: none;
}

.container {
  text-align: center;
  background-color: #2b2b2b;
  border-radius: 15px;
  align-self: center;
  width: 90%;
  max-width: 500px;
  margin-top: 100px;
  padding: 30px;
  box-shadow: 0 0 10px #00000080;
}

.container h2 {
  color: #fff;
  margin-top: 20px;
  margin-bottom: 32px;
  font-size: 32px;
}

.exchange-box {
  flex-direction: column;
  align-items: center;
  gap: 27px;
  margin-bottom: 20px;
  display: flex;
}

.exchange-box .box {
  background-color: #3c3c3c;
  border-radius: 10px;
  align-items: center;
  width: 100%;
  padding: 15px;
  display: flex;
  position: relative;
}

.exchange-box .box img[src="assets/ethereum.svg"] {
  height: 24px;
  margin-left: 4px;
}

.exchange-box .box img, .exchange-box .eclipse-logo {
  height: 24px;
  margin-right: 10px;
}

.exchange-box .box .label {
  color: #888;
  background-color: #2b2b2b;
  padding: 0 5px;
  font-size: 14px;
  position: absolute;
  top: -10px;
  left: 10px;
}

.exchange-box .box select, .exchange-box .box input {
  color: #fff;
  text-align: center;
  box-sizing: border-box;
  background-color: #4a4a4a;
  border: 1px solid #555;
  border-radius: 5px;
  width: calc(100% - 3px);
  padding: 10px;
  font-size: 16px;
}

.exchange-box .box input#to-chain, .exchange-box .box.full-width input#eclipse-wallet {
  text-align: center;
}

#from-chain {
  margin-left: 10px;
}

.exchange-box .box.full-width {
  flex-direction: column;
  align-items: flex-start;
}

.exchange-box .box.full-width .label {
  margin-bottom: 10px;
}

.amount-box {
  margin-bottom: 20px;
}

.amount-box .label {
  color: #888;
  margin-bottom: 5px;
  font-size: 14px;
}

.amount-box input {
  color: #fff;
  box-sizing: border-box;
  background-color: #4a4a4a;
  border: 1px solid #555;
  border-radius: 5px;
  width: 100%;
  max-width: 150px;
  padding: 10px;
  font-size: 16px;
}

.button-box {
  justify-content: center;
  margin-top: 20px;
  display: flex;
}

#bridge-button {
  color: #fff;
  cursor: pointer;
  background-color: #38a169;
  border: none;
  border-radius: 10px;
  padding: 15px 30px;
  font-size: 18px;
  transition: background-color .3s;
}

#bridge-button:hover {
  background-color: #2f855a;
}

#bridge-button:disabled {
  cursor: not-allowed;
  background-color: #666;
}

.note {
  color: #888;
  text-align: left;
  margin-top: 30px;
  margin-left: -8px;
  font-size: 15px;
}

footer {
  background-color: #1e1e1e;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
}

.footer-text {
  color: #888;
  text-align: center;
  flex-grow: 1;
  font-size: 16px;
  font-weight: bold;
}

.footer-text a {
  color: #888;
  text-decoration: underline;
}

.footer-text a:hover {
  color: #38a169;
  text-decoration: underline;
}

.footer-icons {
  gap: 18px;
  margin-right: 50px;
  display: flex;
}

.footer-icons img {
  width: 20px;
  height: 20px;
}

@media (width <= 600px) {
  .container {
    width: 100%;
    padding: 20px;
  }

  .footer-icons img {
    width: 6px;
    height: 6px;
  }
}
/*# sourceMappingURL=index.1eb0af05.css.map */
